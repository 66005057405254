import {Component, OnInit} from '@angular/core';

import {ToastrService} from 'ngx-toastr';

import {ChatService} from '@shared/services/chat.service';
import {DisableService} from "@shared/services/disable.service";
import {TokenService} from "@shared/services/token.service";

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss']
})
export class WrapperComponent implements OnInit {
  public disableClicks: boolean;

  constructor(private _toastr: ToastrService,
              private _chatService: ChatService,
              private _disableService: DisableService,
              public tokenService: TokenService) {
  }

  ngOnInit(): void {
    this._chatService.init();
    this._chatService.notices$
      .subscribe((result) => {
        switch (result.type) {
          case 'message':
            this._toastr.info(result.data.message, result.data.author.name, { closeButton: true });
            break;
          case 'notify':

            const data = {title: '', body: ''};
            switch (result.data.type) {
              case 'NewTask':
                data.title = 'Task event';
                data.body = `<a routerLink="/tasks">${result.data.title}</a> was created!`;
                break;
              case 'NewRequest':
                data.title = 'Request event';
                data.body = `<a routerLink="/requests">${result.data.title}</a> was created!`;
                break;
              case 'NewEmail':
                data.title = 'New email message';
                data.body = `${result.data.title}`;
                break;
            }
            this._toastr.info(data.body, data.title, {enableHtml: true, disableTimeOut: true, closeButton: true});
            break;
        }
      });
    this._disableService.disable.subscribe(value => {
      this.disableClicks = value;
    });
  }
}
