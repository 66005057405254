import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import {NavigationEnd, Router} from "@angular/router";
import { utils } from "@shared/helpers/utils";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private history: string[] = [];

  constructor(private router: Router, private location: Location) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  back(url): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl(url);
    }
  }

  backWithQuery(url, storageFilterName) {
    const filterParams =  utils.clearEmpty(JSON.parse(localStorage.getItem(storageFilterName)));
    this.router.navigate([url], {queryParams: filterParams});
  }
}
