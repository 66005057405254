import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
// import { isPlatformServer } from '@angular/common';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { isString } from '@shared/helpers/functions';

// import { environment } from 'environments/environment';

@Injectable()
export class HttpService implements HttpInterceptor {

  constructor(@Inject(PLATFORM_ID) private _platformId: any,
              private _route: ActivatedRoute,
              private _router: Router,
              private _toastr: ToastrService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.accessToken) {
      const headers = {
        // tslint:disable-next-line
        'Authorization': `Token ${this.accessToken}`
      };

      request = request.clone({
        setHeaders: headers
      });
    }

    return next.handle(request)
      .pipe(
        tap(
          (res: HttpResponse<any>) => { },
          (err: HttpErrorResponse) => {
            if (err.status === 400) {
              if (Array.isArray(err.error.errors?.detail) && err.error.errors?.detail.length) {
                this.makeError(err.error.errors?.detail[0]);
              } else if (err.error.errors?.detail) {
                console.log(err);
                console.log(err.error.errors?.detail);
                this.makeError(err.error.errors?.detail);
              } else if (err.error?.errors) {
                this.makeError(err.error?.errors);
              } else {
                this._toastr.error(err.error, 'Data error', { closeButton: true });
              }
            }
            if (err.status === 403 && err.url.indexOf('channel') < 0) {
              this._toastr.error('Access forbidden!', '403 error', { closeButton: true });
            }

            if (err.status === 500) {
              this._toastr.error('Something went wrong. Try again or contact us.', '500 error', {closeButton: true});
            }

            /*
            if (err.status === 404) {
              if (err.url.indexOf('list=true') === -1) {
                this._router.navigate(['../'], { relativeTo: this._route });
              }
            }
             */
          }
        ));
  }

  makeError(errors: any, parent?: string) {
    if (Array.isArray(errors)) {
      errors.forEach((err) => {
        if (err.code) {
          let title = err.code.replace('_', ' ');
          title = title.charAt(0).toUpperCase() + title.slice(1);
          this._toastr.error(err.detail, title, { closeButton: true });
        }
      });
    } else if (isString(errors)) {
      this._toastr.error(errors, 'Request error', { closeButton: true });
    } else {
      // console.log(errors)
      this._toastr.error(errors.message, errors.status);

      // Object.keys(errors).forEach((key) => {
      //   let title = key.replace('_', ' ');
      //   if (!parent) {
      //     title = title.charAt(0).toUpperCase() + title.slice(1);
      //   }
      //   if (isString(errors[key])) {
      //     this._toastr.error(errors[key], 'Request error', { closeButton: true });
      //   } else if (Array.isArray(errors[key])) {
      //     this._toastr.error(errors[key].join('<br />'), parent ? [parent, title].join(' -> ') : title, { closeButton: true });
      //   } else {
      //     Object.keys(errors[key]).forEach((i) => {
      //       this.makeError(errors[key][i], key);
      //     });
      //   }
      // });
    }
  }

  get accessToken() {
    return localStorage.getItem('accessToken');
  }
}
