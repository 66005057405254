import {Injectable} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class QueryService {

  constructor(private route: ActivatedRoute) {
  }

  getValueFromQueryByName(name) {
    return this.route.snapshot.queryParams[name] || null;
  }
}
