<div class="modal-header d-flex align-items-center justify-content-between">
  <div class="modal-title pull-left font-25 font-weight-normal">
    Currency converter
  </div>
  <button (click)="bsModalRef.hide()"
          aria-label="Close"
          class="btn btn-link p-0"
          type="button">
    <i class="icon icon-modal-close bg-secondary"></i>
  </button>
</div>
<div [formGroup]="formData" class="modal-body p-4">
  <div class="row align-items-center justify-content-between">
    <div class="col-5">
      <form-typeahead [isString]="true" element="named" formControlName="currency_from" [items]="currencies"
                      placeholder="Currency from"></form-typeahead>
      <form-input formControlName="initial_amount" placeholder="I have"></form-input>
      <div class="d-flex justify-content-between mt-2 font-14">
            <div *ngFor="let currency of currencies" class="cursor-pointer text-hover" (click)="addShortCut('currency_from', currency.named)">{{currency.named}}</div>
      </div>
    </div>
    <i class="icon icon-cont-two-arrows col-1 mx-2 cursor-pointer" (click)="switchValues()"></i>
    <div class="col-5">
      <form-typeahead [isString]="true" element="named" formControlName="currency_to" [items]="currencies"
                      placeholder="Currency to"></form-typeahead>
      <form-input formControlName="converted_amount" placeholder="I get"></form-input>
      <div class="d-flex justify-content-between mt-2 font-14">
        <div *ngFor="let currency of currencies" class="mr-1 cursor-pointer text-hover" (click)="addShortCut('currency_to', currency.named)">{{currency.named}}</div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-5">


    </div>
    <div class="col-5">

    </div>
  </div>
  <div class="d-flex font-14">
  </div>
</div>
<div class="modal-footer px-4">
  <button (click)="bsModalRef.hide()"
          class="btn btn-sm btn-outline-danger"
          type="button">
    Close
  </button>
</div>

