import { Injectable } from '@angular/core';
import {Office} from '../offices/offices/interfaces';
import {Observable, throwError} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})


export class ApiService {
    constructor( private http: HttpClient ) {}

    private static handleError(error: any) {
        return throwError(error);
    }

    public getCurrencies(): Observable<any> {
        return this.http.get('/api/v1/currencies/')
            .pipe(
                catchError(ApiService.handleError)) ;
    }

    public getRegions(): Observable<any> {
        return this.http.get('/api/v1/region/').pipe(
            catchError(ApiService.handleError)) ;
    }

    addRegion(newOffice: string, currencies: string[]): Observable<any> {
       const payload = {
           title: newOffice,
           ccy:  currencies
       };

       return this.http.post<any>('/api/v1/region/',
           payload,
           {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }).pipe(
            catchError(ApiService.handleError)) ;

    }

    putOffice(office: string, currencies: string[], id: string): Observable<any> {
        const payload = {
            title: office,
            ccy: currencies
        };
        return this.http.put<any>(`/api/v1/region/${id}`, payload).pipe(
            catchError(ApiService.handleError)) ;
    }

    deleteOffice(id: string): Observable<any> {
        return this.http.delete<any>(`/api/v1/region/${id}`).pipe(
            catchError(ApiService.handleError)) ;
    }
}
