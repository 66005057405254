export const WS = {
  ON: {
    MESSAGE: 'chat.message',
    READING: 'chat.read',
    TYPING: 'chat.typing',
    NOTIFY: 'notification',
  },
  SEND: {
    MESSAGE: 'message',
    TYPING: 'typing',
  }
};
