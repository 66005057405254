import {Component, OnDestroy, OnInit} from '@angular/core';
import LogRocket from 'logrocket';
import {NavigationService} from "@shared/services/navigation.service";
import {Router} from "@angular/router";
import {Subscription} from "rxjs";
import {TokenService} from "@shared/services/token.service";

LogRocket.init('uu2vkq/bsi');

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit, OnDestroy {
  _subs = new Subscription();

  constructor(private _navigationService: NavigationService,
              private _router: Router,
              private _tokenService: TokenService) {
  }

  ngOnInit(): void {
    this._subs.add(
      this._tokenService.userData.subscribe(data => {
        if (data && data.type !== 'TokenCreateView') {
          this._subs.unsubscribe();
          if (data.temporary_password) {
            console.log('111, temporary_password', data);
            this._router.navigate(['/recover']);
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }


}
