import { Input, OnDestroy, OnInit, Directive } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Subscription } from 'rxjs';
import {ToastrService} from "ngx-toastr";

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class FormBase<T> implements OnInit, OnDestroy {
  constructor() {

  }


  // @Input() formData: FormGroup;
  formData: FormGroup;
  subs: Subscription = new Subscription();
  loading: boolean;
  parentId: string;
  data: T = {} as T;
  formType = 'create';

  ngOnInit(): void {
    this.createForm();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  abstract createForm(): void;

}
