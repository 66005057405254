import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor() { }

  navigation() {
    return [
      {
        name: 'Requests',
        link: '/requests',
        icon: 'icon-nav-requests',
        children: [],
        orderForAccountant: 4,
      },
      {
        name: 'Directory',
        link: '/directory',
        icon: 'icon-nav-directory',
        orderForAccountant: 2,
        children: [
          {
            name: 'Clients',
            link: 'clients',
          },
          {
            name: 'Suppliers',
            link: 'suppliers',
          },
          {
            name: 'Managers',
            link: 'managers',
            displayedForRoles: ['Admin'],
          },
          {
            name: 'Services',
            link: 'services',
          },
          // {
          //   name: 'Offices',
          //   link: 'offices',
          // }
          {
            name: 'Banks',
            link: 'banks'
          },
          {
            name: 'Companies',
            link: 'companies',
          }
        ]
      },
      {
        name: 'Accounting',
        link: '/accounting',
        icon: 'icon-nav-accounting',
        displayedForRoles: ['Admin', 'Accountant'],
        orderForAccountant: 1,
        children: [
          {
            name: 'Accounting workspace',
            link: 'accounting-workspace',
            // icon: 'icon-nav-acc-workspace',
          },
          // {
          //   name: 'Client invoices',
          //   link: 'client-invoices',
          // },
          // {
          //   name: 'Supplier invoices',
          //   link: 'supplier-invoices',
          // },
          {
            name: 'Matched data',
            link: 'matched-data',
          },

          {
            name: 'Invoices & Bills',
            link: 'invoices-and-bills',
          },
          {
            name: 'Exchange rates',
            link: 'exchange-rates',
          },
          {
            name: 'Bank cards',
            link: 'bank-cards',
          },
          {
            name: 'Accounting data',
            link: 'accounting-data',
          },
        ]
      },
      {
        name: 'Reports',
        link: '/reports',
        icon: 'icon-nav-reports',
        orderForAccountant: 3,
        children: [
          {
            name: 'Clients',
            link: 'clients',
          },
          {
            name: 'Suppliers',
            link: 'suppliers',
          },
          {
            name: 'Requests',
            link: 'requests',
          },
          {
            name: 'Managers',
            link: 'managers',
          },
          {
            name: 'Invoices',
            link: 'invoices',
          },
          {
            name: 'Debts',
            link: 'debts',
            displayedForRoles: ['Admin', 'Accountant']
          },
          {
            name: 'Fin-Report',
            link: 'fin',
            displayedForRoles: ['Admin', 'Accountant']
          },
          {
            name: "LOG-report",
            link: "log",
            displayedForRoles: ['Admin', 'Accountant']
          },
          {
            name: "Supplier-profitability",
            link: "supplier-profitability"
          }
        ],
      },
      {
        name: 'Tasks',
        link: '/tasks',
        icon: 'icon-nav-tasks',
        children: [],
        displayedForRoles: ['Admin', 'Frontend', 'Backend']
      },
      {
        name: 'Mail agent',
        link: '/mail',
        icon: 'icon-nav-mail',
        children: [],
        displayedForRoles: ['Admin', 'Frontend', 'Backend']
      },
      {
        name: 'Documents',
        link: '/documents',
        icon: 'icon-nav-documents',
        children: [],
        displayedForRoles: ['Admin', 'Frontend', 'Backend']
      }
      /* {
        name: 'Pipeline',
        link: '/pipeline',
        icon: 'icon-nav-pipeline',
        children: []
      },*/
    ];
  }
}
