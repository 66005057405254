import { Component } from '@angular/core';

@Component({
  selector: 'app-roles-redirect',
  templateUrl: 'roles-redirect.component.html',
})
export class RolesRedirectComponent {

  constructor() { }
}
