class Utils {

  public static clearEmpty(object) {
    object = Object.assign({}, object);
    return [Object.keys(object).forEach((key) => {
      if (Array.isArray(object[key])) {
        object[key] = object[key].filter(x => (x !== undefined || x !== null || x !== '' || x !== false));
        return (!object[key].length) && delete object[key];
      }
      return (!object[key]) && delete object[key];
    }), object][1];
  }

  public static makeId() {
    return Math.random().toString(36).substring(2);
  }

  public static makeDate(object) {
    object = Object.assign({}, object);
    Object.keys(object).forEach((key) => {
      if (object[key] instanceof Date) {
        object[key] = object[key].toISOString();
      }
    });
    return object;
  }

  public static downloadFile(data: any, mime: string, name: string, onlyShow = false) {
    const blob = new Blob([data], {type: mime});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    a.href = url;
    if (onlyShow) {
      a.target = '_blank';
    } else {
      a.download = name;
    }
    a.click();
  }
}

export {Utils as utils};
