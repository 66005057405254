<div
  class="d-flex align-items-center border-lighter border-bottom header-wrapper justify-content-between position-relative">
  <app-breadcrumbs></app-breadcrumbs>
  <div class="d-flex align-items-center">
    <div class="d-flex flex-column align-items-center" style="row-gap: 6px">
      <div class="d-flex align-items-center">
        <div class="mr-4">
          <div class="input-group">
            <div class="input-group-prepend mr-1">
              <div class="search-popup d-flex justify-content-between align-items-center" *ngIf="isPopupOpen">
                <input type="text" class="form-control border-0" placeholder="Search"
                       (keyup.enter)="search()" [(ngModel)]="queryString">
                <span class="close-popup" (click)="isPopupOpen=false">X</span>
              </div>
              <span *ngIf="!isPopupOpen" class="search-but input-group-text bg-transparent border-0"
                    (click)="openSearchPopup()" [tooltip]="'Search'" placement="bottom">
               <i class="icon icon-cont-search bg-lighter"></i>
              </span>
            </div>
          </div>
        </div>
        <a [routerLink]=" userRole === 'Accountant' ? '/accounting/accounting-workspace' : '/requests'" class="thumb-list rounded-circle mr-3">
          <i class="icon" [ngClass]="userRole === 'Accountant' ? 'icon-nav-acc-workspace' : 'icon-nav-requests'"></i>
        </a>
        <button type="button"
                class="d-flex justify-content-center align-items-center btn btn-secondary dropdown-toggle mr-3 p-1 pl-2 bg-white font-14"
                style="border: 2px solid #e2e2e2; border-radius: 6px;"
                placement="bottom"
                [popover]="ratesTemplate"
                containerClass="ratesPopover"
                [outsideClick]="true">
          Exchange rates
          <i class="icon icon-cont-wallet-sm bg-dark ml-1"></i>
          <div *ngIf="rateService.rateUpdNeeded"
               class="exclamation-cont bg-danger cursor-pointer rounded-circle d-flex justify-content-center align-items-center ml-2"
               tooltip="Exchange rates were not updated for more than 15 days!">
            <img width="18" height="18" src="/assets/images/icons/exclamation.svg" alt="sign">
          </div>
        </button>

        <ng-template #ratesTemplate>
          <table class="table min-w-100 mb-0">
            <tbody>
            <tr style="background-color: #f4f4f4;">
              <td class="font-weight-bold text-center align-middle"> Pairs</td>
              <td class="font-weight-bold text-center align-middle"> Sell</td>
              <td class="font-weight-bold text-center align-middle"> Buy</td>
            </tr>
            <tr *ngFor="let pair of rateService.ccyPairsList" class="text-center">
              <td class="font-weight-bold align-middle" style="min-width: 80px"> {{pair.original_currency}}
                / {{pair.target_currency}}</td>
              <td>{{rateService.ratesData.pairsObj['sell_' + pair.original_currency + '_' + pair.target_currency]}}</td>
              <td>{{rateService.ratesData.pairsObj['buy_' + pair.original_currency + '_' + pair.target_currency]}}</td>
            </tr>
            </tbody>
          </table>
          <div class="font-16 px-2 py-1 border-2" style="background-color: #f4f4f4;">
            Create date: {{rateService.ratesData.created_at | date: 'dd/MM/yy'}}
          </div>
        </ng-template>
        <div class="btn-group" *ngIf="isXeroAuth !== undefined" dropdown>
          <button type="button"
                  dropdownToggle
                  class="d-flex justify-content-center align-items-center btn dropdown-toggle rounded-circle text-uppercase mr-3"
                  [ngClass]="isXeroAuth ? 'btn-success' : 'btn-secondary text-white'"
                  style="font-size: 10px" placement="bottom">xero
          </button>
          <ul
            *dropdownMenu
            class="dropdown-menu" role="menu">
            <button type="button" class="btn btn-danger mr-3 text-uppercase w-100" (click)="xeroAuth()">Xero auth
            </button>
            <button type="button" class="btn btn-danger text-uppercase w-100" [disabled]="!isXeroAuth"
                    (click)="xeroSync()">Xero sync
            </button>
          </ul>
        </div>
        <button type="button"
                class="d-flex justify-content-center align-items-center btn btn-secondary dropdown-toggle rounded-circle mr-3"
                [tooltip]="'Currency converter'" placement="bottom" (click)="openCurrencyConverterModal()">
          <i class="icon icon-cont-currency-exchange bg-white"></i>
        </button>
        <div class="btn-group" dropdown>
          <button type="button" dropdownToggle
                  class="d-flex justify-content-center align-items-center btn btn-danger dropdown-toggle rounded-circle mr-3"
                  [tooltip]="'Add...'" placement="bottom">
            <i class="icon icon-cont-plus bg-white"></i>
          </button>
          <ul *dropdownMenu class="dropdown-menu" role="menu">
            <li role="menuitem"><a class="dropdown-item" routerLink="/requests/create" target="_blank">New request</a>
            </li>
            <li *ngIf="['Accountant', 'Admin'].includes(userRole)" role="menuitem"><a class="dropdown-item"
                                                                                      routerLink="/requests/create-accounting"
                                                                                      target="_blank">New accounting
              request</a></li>
            <li role="menuitem"><a class="dropdown-item" routerLink="/directory/suppliers/new" target="_blank">New
              supplier</a></li>
            <li role="menuitem"><a class="dropdown-item" routerLink="/directory/clients/new" target="_blank">New
              client</a>
            </li>
          </ul>
        </div>
        <button type="button" class="btn btn-link position-relative border-0 p-0 mr-3"
                [class.notice]="userData?.notify_counter" [tooltip]="'Notifications'" placement="bottom"
                (click)="notify()">
          <i class="icon icon-cont-bell bg-secondary"></i>
        </button>
        <a routerLink="/profile" class="thumb-list rounded-circle mr-3" *ngIf="userData?.avatar; else nameTemplate"
           [style.backgroundImage]="'url(' + userData.avatar + ')'"></a>
        <ng-template #nameTemplate>
          <a routerLink="/profile"
             class="d-flex justify-content-center align-items-center btn btn-secondary rounded-circle"
             [tooltip]="'Profile'" placement="bottom">
            {{initials}}
          </a>
        </ng-template>
      </div>
    </div>
  </div>
</div>

