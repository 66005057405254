import {Component} from '@angular/core';
import {BreadcrumbsService} from "@shared/services/breadcrumbs.service";
import {NavigationService} from "@shared/services/navigation.service";

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent {

  constructor(public breadcrumbsService: BreadcrumbsService,
              private _navigationService: NavigationService) {
  }

  back() {
    this._navigationService.back(this.breadcrumbsService.navigateBackTo);
  }
}
