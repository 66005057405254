<div class="modal-header d-flex align-items-center justify-content-between">
  <div class="modal-title pull-left font-25 font-weight-normal">
    <h2 class="font-weight-normal mb-0">{{data?.id ? 'Update exchange rate' : 'New exchange rate'}}</h2>
  </div>
  <button type="button" class="btn btn-link p-0" aria-label="Close" (click)="bsModalRef.hide()">
    <i class="icon icon-modal-close bg-secondary"></i>
  </button>
</div>
<div class="modal-body p-4">
  <h3 class="mb-0">Main info</h3>
  <form [formGroup]="formData">
    <table class="table min-w-100">
      <tbody>
      <tr style="background-color: #f4f4f4;">
        <td class="font-weight-bold text-center align-middle"> Pairs</td>
        <td class="font-weight-bold text-center align-middle"> Sell</td>
        <td class="font-weight-bold text-center align-middle"> Buy</td>
      </tr>
      <tr *ngFor="let pair of rateService.ccyPairsList">
        <td class="font-weight-bold text-center align-middle"> {{pair.original_currency}}
          / {{pair.target_currency}}</td>
        <td>
          <div class="p-2 min-w-100">
            <form-input placeholder="rate*"
                        formControlName="sell_{{pair.original_currency}}_{{pair.target_currency}}"
                        controlMask="0*.0*"
                        [specialCharacters]="['.']"
                        [readonly]="formType == 'update'">
            </form-input>
          </div>
        </td>
        <td>
          <div class="p-2 min-w-100">
            <form-input placeholder="rate*"
                        formControlName="buy_{{pair.original_currency}}_{{pair.target_currency}}"
                        controlMask="0*.0*"
                        [specialCharacters]="['.']"
                        [readonly]="formType == 'update'">
            </form-input>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </form>
</div>
<div class="modal-footer px-4">
  <button type="button" class="btn btn-sm btn-danger" (click)="onSubmit()" [disabled]="loading">
    {{data?.id ? 'Update' : 'Create'}}
  </button>
</div>
