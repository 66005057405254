import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

import { toFormData } from '@shared/helpers/functions';

@Injectable()
export class DataService implements HttpInterceptor {

  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.params.has('jsonType')) {
      // request.params.delete('type');
    } else {
      if (['POST', 'PATCH', 'PUT'].includes(request.method)) {
        request = request.clone({
          body: toFormData(request.body)
        });
      }
    }

    return next.handle(request);
  }

  // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //
  //   request = request.clone({
  //     body: toFormData(request.body)
  //   });
  //
  //   return next.handle(request)
  //     .pipe(
  //       map(response => response['data'])
  //     );
  // }

}
