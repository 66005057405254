import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {TokenService} from "@shared/services/token.service";

@Injectable({
  providedIn: 'root'
})
export class RoleCheckGuard implements CanActivate {
  constructor(private _tokenService: TokenService, private _router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean  {
    if (this._tokenService.userRole === 'Accountant') {
      this._router.navigate(['/accounting/accounting-workspace']);
    } else {
      this._router.navigate(['/requests']);
    }
    return false;
  }
}
