import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListItemsPipe } from '@shared/pipes/list-items.pipe';
import { OrderByPipe } from '@shared/pipes/order-by.pipe';
import { TextFilterPipe } from '@shared/pipes/text-filter.pipe';
import { CurrencyPipe } from './pipes/currency.pipe';
import { NgxOrderDirective } from './directives/ngx-order.directive';
import { CardPipe } from './pipes/card.pipe';
import { StatusComponent } from './components/status/status.component';
import {FormModule} from "@shared/modules/forms/form.module";
import { RoomTypesComponent } from './components/room-types/room-types.component';
import {RoomMealsComponent} from "@shared/components/room-meals/room-meals.component";
import { ShowStatusPipe } from './pipes/show-status.pipe';
import { InvoiceStatusPipe } from './pipes/invoice-status.pipe';
import { ModalChangeAccountTypeComponent } from './modules/modals/modal-change-account-type/modal-change-account-type.component';
import {SafePipe} from "@shared/pipes/safe-url.pipe";
import { ModalCurrencyConverterComponent } from './components/modal-currency-converter/modal-currency-converter.component';
import { PriceExchangeRateTableComponent } from './components/price-exchange-rate-table/price-exchange-rate-table.component';
import {ModalClarificationsComponent} from "@shared/components/modal-clarifications/modal-clarifications.component";
import { ModalHistoryChangesComponent } from './components/modal-history-changes/modal-history-changes.component';
import { ModalChangesListComponent } from './components/modal-changes-list/modal-changes-list.component';
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import { PaymentMethodSelectComponent } from './components/payment-method-select/payment-method-select.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';

@NgModule({
  declarations: [
    ListItemsPipe,
    OrderByPipe,
    TextFilterPipe,
    CurrencyPipe,
    CardPipe,
    NgxOrderDirective,
    StatusComponent,
    RoomTypesComponent,
    RoomMealsComponent,
    ShowStatusPipe,
    InvoiceStatusPipe,
    ModalChangeAccountTypeComponent,
    SafePipe,
    ModalCurrencyConverterComponent,
    PriceExchangeRateTableComponent,
    ModalClarificationsComponent,
    ModalHistoryChangesComponent,
    ModalChangesListComponent,
    PaymentMethodSelectComponent,
    BreadcrumbsComponent,
  ],
    imports: [
        CommonModule, FormModule, InfiniteScrollModule
    ],
  exports: [
    ListItemsPipe,
    OrderByPipe,
    TextFilterPipe,
    CurrencyPipe,
    CardPipe,
    NgxOrderDirective,
    StatusComponent,
    RoomTypesComponent,
    RoomMealsComponent,
    ShowStatusPipe,
    InvoiceStatusPipe,
    SafePipe,
    PriceExchangeRateTableComponent,
    PaymentMethodSelectComponent,
    BreadcrumbsComponent
  ]
})
export class SharedModule { }
