import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {
  showBreadcrumbs = false;
  breadCrumbsName: string;
  navigateBackTo: string;

  constructor() {
  }

  clearBreadcrumbsData() {
    this.breadCrumbsName = null;
    this.navigateBackTo = null;
    this.showBreadcrumbs = null;
  }
}
