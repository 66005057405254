import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  constructor() {
  }

  getRate(rates, originalCurrency, targetCurrency) {
    const foundRate = rates.find(rate => {
      return rate.original_currency.named === originalCurrency && rate.target_currency.named === targetCurrency;
    });
    return foundRate;
  }

  calcConvertedPrice(rate, initialPrice, quantity?) {
    quantity = quantity || quantity === 0 ? quantity : 1;
    const price = +rate * +initialPrice * +quantity;
    if (price || price === 0) {
      return Number(price.toFixed(2));
    } else {
      return null;
    }
  }
}
