import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { toFormData } from '@shared/helpers/functions';
import { Channel, Message } from '@shared/models/chat';
import { WS } from '@shared/websocket/websocket.events';
import { WebsocketService } from '@shared/websocket';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  notices$: Subject<any> = new Subject();
  message$: Subject<any> = new Subject();
  typings$: Subject<any> = new Subject();
  channels = [];

  constructor(private _http: HttpClient,
              private _wsService: WebsocketService) {
  }

  init() {
    this._wsService.on<any>(WS.ON.MESSAGE)
      .pipe(
        tap((response) => {
          if (!this.channels.includes(response.chat_channel_id)) {
            this.notices$.next({type: 'message', data: response});
          }
        })
      )
      .subscribe((response) => this.message$.next(response));
    this._wsService.on<any>(WS.ON.TYPING)
      .subscribe((response) => this.typings$.next(response));
    this._wsService.on<any>(WS.ON.NOTIFY)
      .subscribe((response) => {
        this.notices$.next({type: 'notify', data: response});
      });
    this._wsService.on<any>(WS.ON.READING)
      .subscribe((response) => {
        // console.log(response);
      });
  }

  addChannel(channelId: string) {
    this.channels.push(channelId);
  }

  delChannel(channelId: string) {
    const index = this.channels.indexOf(channelId);
    if (index > -1) {
      this.channels.splice(index, 1);
    }
  }

  // API

  listChannel(props?: any): Observable<Channel[]> { // chat_channels_list
    return this._http.get(`/chat/channels/`, {params: props})
      .pipe(
        map(response => response['data'])
      );
  }

  itemChannel(channelId: string, props?: any): Observable<Channel> { // chat_channels_read
    return this._http.get(`/chat/channels/${channelId}/`, {params: props})
      .pipe(
        map(response => response['data'])
      );
  }

  createChannel(data: any): Observable<Channel> { // chat_channels_create
    return this._http.post<Channel>(`/chat/channels/`, data)
      .pipe(
        map(response => response['data'])
      );
  }

  listChannelMessage(channelId: string, props?: any): Observable<Message[]> { // chat_channels_messages_list
    return this._http.get(`/chat/channels/${channelId}/messages/`, {params: props})
      .pipe(
        map(response => response['data'])
      );
  }

  itemChannelMessage(messageId: string, props?: any): Observable<Message[]> { // chat_channels_messages_read
    return this._http.get(`/chat/channels/messages/${messageId}/`, {params: props})
      .pipe(
        map(response => response['data'])
      );
  }

  readChannelMessage(data: any): Observable<Message> { // chat_channels_messages_read_create
    return this._http.post<Message>(`/chat/channels/messages/${data.id}/read/`, data)
      .pipe(
        map(response => response['data'])
      );
  }

  createChannelMessage(channelId: string, data: any): Observable<Channel> { // chat_channels_messages_create
    return this._http.post<Channel>(`/chat/channels/${channelId}/messages/`, data)
      .pipe(
        map(response => response['data'])
      );
  }

  updateChannelMessage(data: any): Observable<Message> { // chat_channels_messages_partial_update
    return this._http.patch<Message>(`/chat/channels/messages/${data.id}/`, data)
      .pipe(
        map(response => response['data'])
      );
  }

  deleteChannelMessage(messageId: string): Observable<any> { // chat_channels_messages_delete
    return this._http.delete(`/chat/channels/messages/${messageId}/`);
  }

  createChannelUser(channelId: string, data: any): Observable<Message[]> { // chat_channels_users_create
    return this._http.post(`/chat/channels/${channelId}/users/`, data)
      .pipe(
        map(response => response['data'])
      );
  }

  deleteChannelUser(channelId: string, userId: string): Observable<any> { // chat_channels_users_delete
    return this._http.delete(`/chat/channels/${channelId}/users/${userId}/`);
  }

}
