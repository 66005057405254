import * as dayjs from 'dayjs';

function prepareData(formData: FormData, formValue: any, parent?: any) {

  Object.keys(formValue).forEach((key) => {

    const value = formValue[key];

    if (value instanceof Date) {
      formData.append(parent ? `${parent}[${key}]` : key, dayjs(value).format('YYYY-MM-DDTHH:MM:00.000[Z]'));
    } else

    if (value instanceof File) {
      formData.append(parent ? `${parent}[${key}]` : key, value);
    } else

    if (value instanceof Array) {

      for (let i = 0; value.length > i; i++) {

        if (value[i] instanceof File) {

          formData.append(parent ? `${parent}[${key}]` : `${key}[${i}][file]`, value[i]);

        } else

        if (value[i] instanceof Object) {

          prepareData(formData, value[i], parent ? `${parent}[${key}][${i}]` : `${key}[${i}]`);

        } else  {

          formData.append(parent ? `${parent}[${i}][${key}]` : `${key}[${i}]`, value[i]);

        }

      }

    } else

    if (value instanceof Object) {

      prepareData(formData, value, parent ? `${parent}[${key}]` : key);

    } else {

      if (value !== undefined) {

        formData.append(parent ? `${parent}[${key}]` : key, value);

      }

    }

  });

}

export function toFormData<T>(formValue: T) {
  const formData = new FormData();

  prepareData(formData, formValue);

  return formData;
}

export function isObject(value) {
  return value !== null && typeof value === 'object';
}

export function isString(value) {
  return typeof value === 'string';
}
