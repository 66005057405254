<div class="form-group position-relative mb-0" dropdown #dropdown="bs-dropdown" [isDisabled]="readonly"
     (onHidden)="onHidden()" [insideClick]="multiple" [class.readonly]="readonly">
  <div class="form-default" [class.is-valid]="value && !errors">
    <div class="btn-group border-lighter border-bottom w-100" dropdownToggle>
      <button type="button" class="btn btn-default dropdown-toggle text-left text-ellipsis px-0" style="margin-bottom: -1px"
              [class.filled]="isValue">
        <div class="text-ellipsis" *ngIf="multiple; else singleTemplate">
          <ng-container *ngFor="let item of value; let isLast = last">{{item[display]}}{{(!isLast ? ', ' : '')}}</ng-container>
        </div>
        <ng-template #singleTemplate>{{isValue ? (value | displayPipe:display) : ''}}</ng-template>
      </button>
      <label class="form-control-label">{{placeholder}}</label>
      <button type="button" class="btn btn-caret d-flex align-items-center flex-grow-0 pr-0">
        <i class="icon icon-cont-caret bg-secondary"></i>
      </button>
    </div>
    <div class="invalid-feedback">
      <div *ngIf="errors?.required">Required field</div>
    </div>
  </div>
  <div class="dropdown-menu dropdown-menu-bottom min-w-100" *dropdownMenu role="menu">
    <div class="form-group m-0" (click)="$event.stopPropagation();" *ngIf="filtered">
      <input type="text" class="form-control px-3" placeholder="Start typing..." [(ngModel)]="queryStr">
    </div>
    <ng-container [ngSwitch]="multiple">
      <div class="dropdown-wrap" *ngSwitchCase="'multi'">
        <ng-container [ngTemplateOutlet]="headerTemplate"></ng-container>
        <div class="dropdown-item p-0" *ngFor="let item of items | filterPipe:queryStr; let idx = index">
          <div class="form-group form-check bf-checkbox d-flex align-items-center mb-0" [class.checked]="dropdownItem.checked">
            <input type="checkbox" class="form-check-input" id="item_{{idx}}" #dropdownItem>
            <label class="form-check-label d-flex align-items-center" for="item_{{idx}}"
                   (click)="writeMultiValue(item, dropdownItem.checked)">
              {{item[display]}}<span class="text-right text-secondary ml-auto" *ngIf="extra">{{item[extra]}}</span>
            </label>
          </div>
        </div>
        <ng-container [ngTemplateOutlet]="footerTemplate"></ng-container>
      </div>
      <div class="dropdown-wrap" *ngSwitchCase="'group'">
        <ng-container [ngTemplateOutlet]="headerTemplate"></ng-container>
        <div class="dropdown-group" *ngFor="let group of items; let idx = index">
          <div class="dropdown-header text-dark">{{group.title}}</div>
          <div class="dropdown-item border-0 p-0" *ngFor="let item of group.children | filterPipe:queryStr; let i = index">
            <div class="form-group form-check bf-checkbox d-flex align-items-center mb-0" [class.checked]="dropdownItem.checked">
              <input type="checkbox" class="form-check-input" id="item_{{idx}}_{{i}}" #dropdownItem>
              <label class="form-check-label d-flex align-items-center" for="item_{{idx}}_{{i}}"
                     (click)="writeMultiValue(dropdownItem.checked, item)">
                {{item[display]}}<span class="text-right text-secondary ml-auto" *ngIf="extra">{{item[extra]}}</span>
              </label>
            </div>
          </div>
        </div>
        <ng-container [ngTemplateOutlet]="footerTemplate"></ng-container>
      </div>
      <div class="dropdown-wrap" *ngSwitchDefault>
        <button class="dropdown-item" type="button" *ngIf="value && !required && !withoutClear" (click)="writeValue(null); $event.stopPropagation();">Clear</button>

        <button class="dropdown-item" type="button" *ngFor="let item of items | filterPipe:queryStr"
                (click)="writeValue(item)">
          {{item | displayPipe:display}}<span class="text-right text-secondary ml-auto" *ngIf="extra">{{item[extra]}}</span>
        </button>
      </div>
    </ng-container>
  </div>
</div>
<ng-template #headerTemplate>
  <ng-content select="[header]"></ng-content>
</ng-template>
<ng-template #footerTemplate>
  <ng-content select="[footer]"></ng-content>
</ng-template>
