import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { API_BASE } from '@shared/helpers/constants';

@Injectable({
  providedIn: 'root'
})
export class XeroService {

  constructor(private _http: HttpClient) { }

  auth(props?: any): Observable<any> {
    return this._http.get(`${API_BASE}/xero/auth/`, {params: props})
      .pipe(
        map(response => response['data'])
      );
  }

  code(props?: any): Observable<any> {
    return this._http.get(`${API_BASE}/xero/code/`, {params: props})
      .pipe(
        map(response => response['data'])
      );
  }

  sync(props?: any): Observable<any> {
    return this._http.get(`${API_BASE}/xero/contacts/`, {params: props})
      .pipe(
        map(response => response['data'])
      );
  }

  isAuth(): Observable<any> {
    return this._http.get(`${API_BASE}/xero/isauth/`);
  }

}
